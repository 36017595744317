// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  target: 'development',
  useEmulators: true,
  logLevel: NgxLoggerLevel.TRACE,
  firebaseConfig: {
    apiKey: 'AIzaSyBfQL_PF6qJo-SgPZ5lNYNYpBmNisS4e00',
    authDomain: 'arbitral-dev.firebaseapp.com',
    projectId: 'arbitral-dev',
    storageBucket: 'arbitral-dev.appspot.com',
    messagingSenderId: '184276137852',
    appId: '1:184276137852:web:b54bb4acffab6622aa96f9',
    measurementId: 'G-88ZVFTBM2V'
  },
  vapidKey: 'BO2FH3hrswzUaCnXjN5kHNkXMUij4ilOrBmri7uS9ofGTLy9arMG-dNjZOnEVA6vhDlAS2nodaqzxtghqngPwTk',
  algoliaConfig: {
    appId: 'VKMIYB88G2',
    apiKey: '71e7f28f8fb186b7452184878dd71f8b'
  },
  tenorKey: 'AIzaSyBfQL_PF6qJo-SgPZ5lNYNYpBmNisS4e00',
  recaptcha3SiteKey: '6LejnKwiAAAAAI_oNeMwEWSKPDwNYpMljPvBzUk5',
  sentryConf: {
    dsn: '',
    tracingOrigins: ['localhost']
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
