import { Pipe, PipeTransform } from '@angular/core';
import { htmlToText } from '@arbitral/common/app';

// Similar to filterBy, but it does 'contains', not 'equal to'
@Pipe({
  name: 'searchBy'
})
export class SearchByPipe implements PipeTransform {
  transform(value: any[], field: string, search: string, contentType: 'text' | 'html' = 'text'): unknown {
    if (!search || !value?.length) {
      return value;
    }

    const fieldValue = (v: any, f: string) => {
      f.split('.').forEach((ff) => (v = v && v[ff]));
      return v;
    };

    return value.filter((v) => {
      let vv: string = fieldValue(v, field);

      if (!vv) {
        return false;
      }

      if (contentType === 'html') {
        vv = htmlToText(vv);
      }

      return vv.toLowerCase().includes(search.toLowerCase());
    });
  }
}
