import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { SearchParameters, InstantSearchConfig } from 'angular-instantsearch/instantsearch/instantsearch';
import { getIndexName, updateQueryParams } from 'app/shared/utils';
import { AlgoliaSearchService } from './search.service';
import { QuerySuggestion } from './search-suggestions.component ';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'fuseSearch',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnChanges {
  @Input() indexName: string;
  @Input() minlength: number = 3;
  @Input() placeholder: string = 'Search';
  @Input() debounce: number = 300;
  @Input() searchableAttrs: string[];

  @Output() searchChange = new EventEmitter<{ query?: string; filter?: string[] }>();

  config: InstantSearchConfig;

  searchParameters: SearchParameters = { query: '' };

  defaultQuery = '';
  opened: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private searchService: AlgoliaSearchService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'search-appearance-bar': true,
      'search-opened': this.opened
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.config = {
      indexName: getIndexName(this.indexName) + '_query_suggestions',
      searchClient: this.searchService.searchClient
    };

    const query = this.route.snapshot.queryParams.search;

    if (query) {
      this.opened = true;
      this.defaultQuery = query;
      this.searchChange.emit({ query });
    }
  }

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    // Appearance
    if ('appearance' in changes) {
      // To prevent any issues, close the
      // search after changing the appearance
      this.opened = false;
    }
  }

  setQuery({ query, category, filter }: QuerySuggestion) {
    this.searchParameters = {
      ...this.searchParameters,
      query,
      ...(category
        ? {
            disjunctiveFacets: ['categories'],
            disjunctiveFacetsRefinements: { categories: [category] }
          }
        : {})
    };

    this.updateQueryForSearch(query, filter);
    this.changeDetectorRef.markForCheck();
  }

  stopSearch() {
    this.opened = false;
    this.defaultQuery = '';

    const queryParams = Object.assign({}, this.route.snapshot.queryParams);
    delete queryParams['search'];
    delete queryParams['searchAttrs'];

    updateQueryParams(queryParams, '');
    this.searchChange.emit();
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  private async updateQueryForSearch(query: string, filter?: string[]) {
    let queryParams = Object.assign({}, this.route.snapshot.queryParams);
    delete queryParams['search'];
    delete queryParams['searchAttrs'];

    if (query) {
      queryParams = { search: query, ...queryParams };
    }

    if (filter?.length) {
      queryParams = { searchAttrs: filter.join(), ...queryParams };
    }

    updateQueryParams(queryParams, '');
    this.searchChange.emit({ query, filter });
  }
}
