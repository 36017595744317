import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userHandle'
})
export class UserHandlePipe implements PipeTransform {
  transform(value: string, symbol: string = '@'): string {
    return symbol + value;
  }
}
