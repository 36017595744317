import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform<T>(value: T[], field: string, order: 'asc' | 'desc' = 'desc'): T[] {
    // order for nested values (eg: createdTs.seconds)
    const fieldValue = (v: any, f: string) => {
      f.split('.').forEach((ff) => (v = v && v[ff]));
      return v;
    };

    if (value?.length <= 1 || !field) {
      return value;
    }

    return value.sort((a, b) => {
      if (order === 'desc') {
        return fieldValue(a, field) < fieldValue(b, field) ? 1 : -1;
      } else {
        return fieldValue(a, field) > fieldValue(b, field) ? 1 : -1;
      }
    });
  }
}
