import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Confirm password validator
 *
 */
export const confirmPasswordValidator =
  (ctrlPassword: string, ctrlPasswordConfirm: string) =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!control?.parent) {
      return null;
    }

    const password = control.parent.get(ctrlPassword);
    const passwordConfirm = control.parent.get(ctrlPasswordConfirm);

    if (!password || !passwordConfirm || passwordConfirm.value === '' || password.value === passwordConfirm.value) {
      return null;
    }

    return { passwordsNotMatching: true };
  };
