<div class="w-full hidden sm:flex sm:min-w-80 items-center" *ngIf="!opened">
  <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded fuse-mat-dense w-full">
    <mat-icon matPrefix class="icon-size-5" [svgIcon]="'heroicons_outline:search'"></mat-icon>
    <input matInput [placeholder]="'Search'" (click)="opened = true" class="py-2 h-6" readonly />
  </mat-form-field>
</div>
<button class="sm:hidden" mat-icon-button *ngIf="!opened" (click)="opened = true">
  <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
</button>

<ais-instantsearch [config]="config" *ngIf="opened">
  <ais-configure [searchParameters]="{ hitsPerPage: 5 }"></ais-configure>
  <div class="flex items-center">
    <search-suggestions
      [placeholder]="placeholder"
      [minlength]="minlength"
      [initialValue]="defaultQuery"
      [searchableAttrs]="searchableAttrs"
      (querySuggestionClick)="setQuery($event)"
      (stopSearch)="stopSearch()"
    ></search-suggestions>
  </div>
</ais-instantsearch>
