/* eslint-disable max-len */
import type { Hit } from '@algolia/client-search';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch';
import { Constants } from 'app/constants';
import connectAutocomplete, {
  AutocompleteWidgetDescription,
  AutocompleteConnectorParams
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { deepCopy } from '@firebase/util';
import { MatDialog } from '@angular/material/dialog';
import { SearchFilterComponent } from './search-filter.component';
import { filter } from 'rxjs';

export type QuerySuggestion = {
  query: string;
  category?: string | null;
  filter?: string[];
};

type QuerySuggestionHit = Hit<{
  instant_search?: {
    facets?: {
      exact_matches?: {
        categories?: ReadonlyArray<{ value: string; count: number }>;
      };
    };
  };
}>;

@Component({
  selector: 'search-suggestions',
  template: `
    <mat-form-field
      class="fuse-mat-no-subscript fuse-mat-rounded absolute inset-0 flex items-center ml-50 w-[calc(100%-theme(space.50))] mr-3 z-99 h-full max-h-14"
      @slideOutRight
    >
      <mat-icon matPrefix class="icon-size-5" [svgIcon]="'heroicons_outline:search'"></mat-icon>
      <input
        matInput
        [placeholder]="placeholder"
        class="w-full py-2 h-6"
        [matAutocomplete]="searchSuggestions"
        (keyup)="handleKeyUp($event)"
        (keydown)="handleKeyDown($event)"
        #searchInput
      />
      <mat-autocomplete
        [displayWith]="getOptionLabel"
        class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
        [disableRipple]="true"
        (optionSelected)="emitSearch($event.option.value)"
        #searchSuggestions="matAutocomplete"
      >
        <mat-option *ngFor="let hit of suggestions" [value]="{ query: hit.query }" class="px-2">
          <ng-container *ngIf="hit.objectID; else searchFor">
            <mat-icon matPrefix class="icon-size-6" [svgIcon]="'heroicons_outline:search'"></mat-icon>
            {{ hit.query }}
          </ng-container>

          <ng-template #searchFor>
            <mat-icon matPrefix class="icon-size-6 text-primary" [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <span class="text-primary">
              Search for <span class="text-primary-600">{{ hit.query }}</span>
            </span>
          </ng-template>
        </mat-option>
      </mat-autocomplete>

      <button matSuffix mat-icon-button [color]="hasFilter ? 'primary' : ''" (click)="openSearchFilter($event)">
        <mat-icon
          class="icon-size-4"
          [svgIcon]="hasFilter ? 'heroicons_solid:filter' : 'heroicons_outline:filter'"
        ></mat-icon>
      </button>

      <button matSuffix mat-icon-button (click)="stopSearch.emit()">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
    </mat-form-field>
  `,
  animations: fuseAnimations,
  styles: [
    `
      search-suggestions {
        .mat-form-field-wrapper {
          @apply w-full;
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          @apply h-full my-auto;
        }
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class SearchSuggestionsComponent
  extends TypedBaseWidget<AutocompleteWidgetDescription, AutocompleteConnectorParams>
  implements OnInit, AfterViewInit
{
  @Input()
  initialValue = '';

  @Input()
  placeholder: string = 'Search';

  @Input()
  minlength: number;

  @Input()
  searchableAttrs: string[] = [];

  @Output()
  querySuggestionClick = new EventEmitter<QuerySuggestion>();

  @Output()
  stopSearch = new EventEmitter();

  @ViewChild(MatAutocompleteTrigger)
  autocomplete: MatAutocompleteTrigger;

  suggestions = [];
  selectedAttrs = [];

  /**
   * Setter for bar search input
   *
   * @param el
   */
  @ViewChild('searchInput')
  set searchInputEl(el: ElementRef) {
    this.searchInput = el.nativeElement;
    this.searchInput.focus();
  }

  @Constants('SEARCH_MIN_CHARACTERS')
  searchMinCharacters: number;

  state?: AutocompleteWidgetDescription['renderState'];
  suggestionIndicies = [];

  private searchInput: HTMLInputElement;

  get hasFilter() {
    return this.selectedAttrs?.length && this.selectedAttrs?.length !== this.searchableAttrs.length;
  }

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private _matDialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super('SearchSuggestionsComponent');
  }

  ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();

    this.selectedAttrs = this.searchableAttrs;

    const { searchAttrs } = this.route.snapshot.queryParams;
    if (searchAttrs) {
      this.selectedAttrs = searchAttrs.split(',');
    }
  }

  ngAfterViewInit() {
    this.searchInput.value = this.initialValue;
  }

  getOptionLabel(querySuggestion: QuerySuggestion) {
    return querySuggestion.query;
  }

  handleKeyUp(event: KeyboardEvent) {
    const query = this.searchInput?.value;

    if (event.key.startsWith('Arrow')) {
      return;
    }

    if (Number(query?.length) < this.minlength) {
      this.suggestions = [];
      return;
    }

    this.state?.refine((event.target as any).value);
    const indices = this.state?.indices ?? [];

    this.suggestions = deepCopy(indices[0]?.hits ?? []);
    if (query.trimStart().trimEnd()) {
      this.suggestions.push({ query } as any);
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.autocomplete.closePanel();
      this.emitSearch();
    } else if (event.code === 'Escape') {
      this.stopSearch.emit();
    }
  }

  emitSearch(qs: QuerySuggestion = { query: this.searchInput?.value }) {
    qs.filter = this.hasFilter ? this.selectedAttrs : null;
    this.querySuggestionClick.emit(qs);
  }

  openSearchFilter(event: any) {
    event.stopPropagation();

    const dialogRef = this._matDialog.open(SearchFilterComponent, {
      panelClass: 'dialog-pane',
      data: {
        searchableAttrs: this.searchableAttrs,
        selectedAttrs: this.selectedAttrs
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.selectedAttrs = res;
        this.emitSearch();
      });
  }
}
