import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[userTooltip]',
  standalone: true
})
export class UserTooltipDirective implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  // TODO: append primeng tooltip direcitve to host element
  // https://github.com/angular/angular/issues/8785
  ngOnInit() {
    const el = this.elementRef.nativeElement;
  }
}
