import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 * filterBy(equalsTo)
 *
 * @example
 * arrayData | filterBy: <fieldName>: 'filter'
 * arrayData | filterBy: <fieldName>: ['filter1', 'filter2']
 */
@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform<T>(value: T[], field: string, filter: any | any[]): T[] {
    // filter for nested objects
    const fieldValue = (v: any, f: string) => {
      f.split('.').forEach((ff) => (v = v && v[ff]));
      return v;
    };

    if (filter.length === undefined) {
      return value.filter((v) => v[field] === filter);
    }

    if (filter.length === 0) {
      return value;
    }

    return value.filter((v) => {
      const vv = fieldValue(v, field);

      if (!vv) {
        return false;
      }

      return typeof vv !== 'object' ? filter.includes(vv) : filter.some((f: any) => vv.includes(f));
    });
  }
}
